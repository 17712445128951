.mt-0 {
  margin-bottom: 3em;
  margin-right: 2em;
}

#skills {
  background-color: $white-color;
  color: $dark-blue-text;
  margin-top: -10rem;
  padding-top: 15rem;

  @include respond(tab-land) {
    margin-top: 0;
    padding-top: 5rem;
  }

  .skills-wrapper {
    // margin-bottom: 15rem;

    /* Make sure that padding behaves as expected */
    // * {
    //   box-sizing: border-box
    // }
    // transition: all 0.2s ease-out;
    /* Container for skill bars */

    // * {
    //   margin: 0;
    //   padding-bottom: 2em;
    //   box-sizing: border-box;
    // }
   

    .progress {
      width: 100%;
      height: 30px;
      background-color: grey;
      border-radius: 10px;
    }

    .progress .progress-bar {
      width: 50%;
      height: 100%;
      background-color: #159895;
      border-radius: 10px;
      position: relative;
      transition: all .5s;
    }

    .progress-bar span {
      font: 2em verdana;
      text-align: right;
      top: 0;
      padding-right: 10;
      color: white;
    }

    // .containerskills {
    //   width: 100%;
    //   /* Full width */
    //   background-color: #ddd;
    //   /* Grey background */
    //   margin-bottom: 2rem;
    // }

    // .skills {
    //   text-align: right;
    //   /* Right-align text */
    //   padding-top: 10px;
    //   /* Add top padding */
    //   padding-bottom: 10px;
    //   /* Add bottom padding */
    //   padding-right: 20px;
    //   color: white;
    //   /* White text color */
    // }
    

    // .html {
    //   width: 90%;
    //   background-color: #159895;
    // }

    // /* Green */
    // .css {
    //   width: 80%;
    //   background-color: #1A5F7A;
    // }

    // /* Blue */
    // .js {
    //   width: 65%;
    //   background-color: #002B5B;
    // }

    // /* Red */
    // .php {
    //   width: 60%;
    //   background-color: #57C5B6;
    // }

    /* Dark Grey */

    @include respond(phone) {
      margin-bottom: 0rem;
    }

    .row {
      // margin-bottom: 8rem;

      @include respond(phone) {
        margin-bottom: 4rem;
      }
    }

    &__text {
      text-align: left;

      @include respond(phone) {
        margin-bottom: 2.5rem !important;
      }

      @include respond(tab-land) {
        margin-bottom: 4.8rem;
      }

      &-title {
        font-weight: bold;
        margin-bottom: 1.8rem;
        font-size: $mid-font-size;

        @include respond(phone) {
          font-size: 2rem;
        }
      }

      & p>a {
        color: $secondary-color;
      }
    }

    &__image {
      width: 90%;
      margin: 0 auto;

      @include respond(tab-land) {
        width: 100%;
        margin: 0;
      }

      & .thumbnail {
        border: none;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        transition: all 0.2s ease-out;
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
        transition: 0.5s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
          0.5s box-shadow,
          0.5s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);

        @include respond(phone) {
          border: 1px solid $light-grey;
          box-shadow: none;
          margin-bottom: 3.2rem;
        }
      }
    }
  }
}