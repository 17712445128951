* {
  margin: 0;
}


.navbar {
  background: #ffffff;
  font-size: 20px;

  li {
    display: inline;

    a {
      color: var(--color-darker-gray);
      font-family: var(--font-sans);
      text-decoration: none;
      font-weight: 500;
      letter-spacing: .01em;
      text-transform: uppercase;
      padding: 14px 16px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.navbar-toggler-icon{
  width: 2.5em;
  height: 3.5em;
}